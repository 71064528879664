@import url(https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Do Hyeon', sans-serif;
  background-color: #3a3a4b;
  color: #fff;
}

.coin-app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 64px;
  color: #fff;
}

.coin-search {
  margin-bottom: 64px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.coin-text {
  margin-bottom: 32px;
  text-align: center;
}

.fart {
  color: #fff;
}

.coin-input {
  padding-left: 16px;
  width: 300px;
  height: 50px;
  border-radius: 4px;
  border: none;
  background-image: linear-gradient(
    -225deg,
     #ac32e4 0%,
     #7918f2 48%,
     #4801ff 100%);
  color: #e2e2e2;
}

.coin-input::-webkit-input-placeholder {
  color: #e2e2e2;
}

.coin-input:-ms-input-placeholder {
  color: #e2e2e2;
}

.coin-input::placeholder {
  color: #e2e2e2;
}
.coin-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.coin-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    justify-items: start;
    -webkit-align-items: center;
            align-items: center;
    height: 80px;
    border-bottom: 1px solid #d7d7d7;
    width: 900px;
}

.coin {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 24px;
    min-width: 300px;
}

.coin h1 {
    font-size: 14px;
    width:  150px;
}

.coin img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.coin-symbol {
    text-transform: uppercase;
}

.coin-data {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
}

.coin-price {
    width: 110px;
}

.coin-volume {
    width: 200px;
}

.coin-marketcap {
    width: 240px;
}

.coin-percent {
    width: 80px;
}

.red {
    color: #f00606;
}

.green {
    color: #11d811
}
